.portfolio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(9px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999999999;
    animation: fadeIn 0.3s ease-out;
}

.modalContent {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 900px;
    height: 100%;
    max-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: zoomIn 0.3s ease-out;
}

.zoomedImage {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #000;
}

.categories {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-top: -2rem;
}

.categoryTag {
    margin-top: 3rem;
    position: relative;
    border-radius: 3em;
    background: transparent;
    width: auto;
    padding: 1rem;
    color: #777168;
    border: 1px solid transparent;
    background-clip: padding-box;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 400;
    cursor: pointer;
    overflow: hidden;
    transition: color 0.3s ease;
}

.categoryTag::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 64px;
    padding: 2px;
    background: linear-gradient(97.37deg, #777168 2.35%, rgba(119, 113, 104, 0) 85.79%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    z-index: -1;
}

.categoryTag::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 100%;
    bottom: 2px;
    border-radius: 100px;
    background-color: #E1DACF;
    z-index: -1;
    transition: right 0.4s ease;
    overflow: hidden;
}

.categoryTag:hover::after {
    right: 0;
}

.categoryTag:hover {
    color: #555555;
}

.categoryTag.active {
    background-color: #E1DACF;
    color: #555555;
    border-radius: 50px;
    pointer-events: none;
}
.categoryTag:hover:not(.active)::after {
    right: 0;
}
.categoryTag:hover:not(.active) {
    color: #555555;
}

.categorySelect {
    display: none;
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 0.8rem;
    padding: 0.5rem;
    border-radius: 20px;
    margin-top: 1rem;
    position: relative;
}

.arrowDown {
    display: none;
    width: 1rem;
    height: 1rem;
}

.categoriesTitle {
    margin-top: 4rem;
    font-family: "Montserrat Alternates", sans-serif;
    text-align: center;
}

.categoriesTitle p {
    font-size: 2.3rem;
    color: #555555;
}

.categoriesTitle .portfolioSectionDivider {
    width: 6rem;
    margin: 0.3rem auto;
    border: 0;
    height: 4rem;
    background: #BAA588;
    clip-path: ellipse(49% 2% at 50% 7%);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.categoriesContainer {
    width: 85%;
    margin-top: 2rem;
    padding: 1rem;
}

.slideBlock {
    position: relative;
    width: 100%;
    height: 300px !important;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat Alternates", sans-serif;
    color: #555555;
    text-align: center;
    overflow: hidden;
    transition: height 0.3s ease;
}

.slideBlock.sketching {
    height: 400px !important;
}

.portfolioImage {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    border-radius: 1rem;
    object-fit: cover;
}

.hoverEffect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.slideBlock:hover .hoverEffect {
    opacity: 1;
}

.seeButton {
    border: none;
    width: 4rem;
    border-radius: 50%;
    padding: 1rem 1rem;
    background: #fff2d794;
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease;
    text-align: center;
}

.seeButton:hover {
    transform: scale(1.1);
}

.sliderControls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 85%;
    margin-top: 1rem;
    padding: 1rem;
    flex-wrap: wrap;
}

.sliderControls button {
    border: 1px solid #555555;
    padding: 1rem;
    cursor: pointer;
    border-radius: 50px;
    margin: 0.5rem;
    width: 6rem;
    text-align: center;
    color: #555555;
    background-color: #F0E7E0;
}

.sliderControls .leftBtn {
    width: 4rem;
    background-color: #F0E7E0;
    border: 1px solid #BEB7AA;
}

.viewMoreButton {
    display: none;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .categories {
        width: 80%;
        flex-wrap: wrap;
        gap: 0;
        margin-top: 0;
    }

    .categoryTag {
        margin: 1rem 0;
        padding: 12px 5px;
    }

    .categoriesTitle p {
        width: 90%;
        font-size: 2rem;
        text-align: center;
    }

    .categoriesContainer {
        grid-template-columns: repeat(2, 1fr);
    }

    .sliderControls {
        justify-content: center;
    }
}

@media (min-width: 750px) and (max-width: 925px) {
    .categorySelect {
        display: inline-block;
        width: 100%;
        padding: 1rem;
        background-color: #E1DACF;
        color: #777168;
        border: none;
        outline: none;
        position: relative;
    }

    .categoryTag {
        display: none;
    }
}

@media (max-width: 768px) {
    .categoriesTitle p {
        width: 90%;
        font-size: 1.8rem;
        text-align: center;
    }

    .categoriesContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .categoryTag {
        display: none;
    }

    .categorySelect {
        display: inline-block;
        width: 100%;
        padding: 1rem;
        background-color: #E1DACF;
        color: #777168;
        border: none;
        outline: none;
        position: relative;
    }

    .sliderControls {
        justify-content: center;
    }

    .sliderControls .leftBtn {
        width: 6rem;
        border: 1px solid #555555;
        background-color: #F0E7E0;
    }

    .viewMoreButton {
        display: inline-block;
    }

    .selectArrow {
        width: 2rem;
        height: 2rem;
    }
}

@media (max-width: 480px) {
    .categoriesTitle p {
        width: 100%;
        font-size: 1.5rem;
        text-align: center;
    }

    .categoriesContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .sliderControls {
        justify-content: center;
    }

    .sliderControls button {
        width: 5rem;
        padding: 0.5rem;
        font-size: 0.8rem;
    }

    .sliderControls .leftBtn {
        width: 5rem;
        border: 1px solid #555555;
        background-color: #F0E7E0;
    }

    .viewMoreButton {
        display: inline-block;
    }
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    z-index: 1001;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}
