.overlay {
    background: linear-gradient(to right,
    rgba(79, 70, 54, 0.547) 0%,
    rgba(62, 54, 38, 0.52) 100%);
    transition: all 0.3s ease;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(20px);
    z-index: 2000;
    display: flex;
    font-family: "Montserrat Alternates", sans-serif;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.contactUs {
    display: flex;
    justify-content: space-between;
    padding: 3rem 2rem;
    width: 100%;
    position: relative;
    height: 100%;
    gap: 4rem;
    overflow: hidden;
}

.closeButton {
    position: absolute;
    top: 3%;
    right: 3%;
    background: none;
    border: none;
    font-size: 1.6rem;
    color: #fff;
    cursor: pointer;
    z-index: 10;
    width: 2rem;
    height: 2rem;
}

.contactInformation {
    width: 40%;
    height: 90vh;
    padding: 2rem;
    border-radius: 20px;
    background-color: #EFE6DF;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 4rem; /* Adjusted padding to bring the section slightly down */
    padding-bottom: 2rem;
}

.contactTitles .title {
    font-size: 1.8rem; /* Adjusted font size */
    margin-bottom: 1rem;
    color: #5F5F5F;
    font-weight: 500;
    text-align: center;
}

.contactTitles .subtitle {
    font-size: 1rem; /* Adjusted font size */
    color: #474747;
    margin-bottom: 4rem;
}

.contactDetails {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    flex: 1;
    justify-content: center;
}

.contactDetails .detailItem {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.contactDetails img {
    margin-right: 10px;
}

.socials {
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 2rem;
    left: 2rem;
}

.socials img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.backgrounImg {
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
    width: 20rem;
}

.contactForm {
    width: 70%;
    height: 90vh;
    padding: 3rem;
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.sendMessageButtonContainer {
    position: absolute;
    bottom: 0;
    right: 2rem;
    width: 100%;
    text-align: right;
}

.sendMessageButton {
    padding: 1.2rem;
    background-color: #BAA588;
    color: #fff;
    border-radius: 40px;
    cursor: pointer;
    border: none;
    outline: none;
    width: 12rem;
    font-family: "Montserrat Alternates", sans-serif;
    box-shadow: 0px 2px 18px 0px rgba(85, 84, 84, 0.75);
    z-index: 1;
}


.letterSendLabel {
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: 1rem;
    right: 10px;
    z-index: 2;
}

.letterSend {
    width: 22rem;
}

.formRow {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.inputField {
    width: 100%;
    padding: 1rem 0;
    border: none;
    margin-bottom: 1rem;
    outline: none;
    box-sizing: border-box;
    resize: none;
    border-bottom: 1px solid #fff;
    background: transparent;
    color: #fff;
    font-size: 1rem;
}
.personalInfoContainer {
    display: flex;
}
.textArea {
    width: 93%;
    padding: 1rem 1rem 0 ;
    border: none;
    border-bottom: 1px solid #fff;
    background: transparent;
    resize: none;
    outline: none;
    color: #fff;
    box-sizing: border-box;
    font-size: 1rem;
    font-family: "Montserrat Alternates", sans-serif;
}

.textFormGroup label {
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    padding: 15px;
}
.textFormGroup  {
    transform: translate(10px, 40px);

}

.textArea::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.formGroup label {
    color: #fff;
}

.formGroup {
    margin-bottom: 1rem;
    color: #fff;
}

.formGroup div {
    padding: 1rem 0;
}

.formGroup input {
    margin-left: 1rem;
}

.SelectFormGroup {
    color: #fff;
}

.checkbox {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    gap: 1rem;
    font-size: 1rem;
}

.checkbox input {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 5px;
    vertical-align: sub;
}
.checkbox label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: "Montserrat Alternates", sans-serif;
    gap: 0.5rem;
    margin-bottom: 1rem;
    position: relative;
    padding-left: 2rem;
}

.checkbox input {
    opacity: 0;
    position: absolute;
    left: 0;
    cursor: pointer;
}

.checkbox .customCheckbox {
    width: 18px;
    height: 18px;
    background-color: #EFE6DF;
    border-radius: 5px;
    border: 2px solid #BAA588;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.checkbox input:checked + .customCheckbox {
    background-color: #BAA588;
    border-color: #BAA588;
}

.checkbox .customCheckbox::after {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    background-color: white;
    border-radius: 3px;
    opacity: 0;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
}

.checkbox input:checked + .customCheckbox::after {
    opacity: 1;
    transform: scale(1);
}

input[type="radio"] {
    width: auto;
    border: none;
    width: 1rem;
    height: 1rem;
    align-items: center;
    outline: none;
    margin-right: 0.4rem;
}

.formgroupTitle {
    padding: 15px;
    font-size: 1rem;
    font-weight: 500;
}

.formGroup .formgroupTitle {
    font-weight: 500;
}

.formRow {
    display: flex;
    justify-content: space-between;
}

.formGroup input {
    width: 100%;
}

.formGroup {
    width: 43%;
}

.formGroup label {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
}

.checkbox label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: "Montserrat Alternates", sans-serif;
    gap: 0.3rem;
    flex-basis: calc(50% - 0.5rem);
    margin-bottom: 1rem;
}

.checkbox label input {
    border-radius: 30px;
    width: 15px;
    height: 15px;
}

/* Responsive Styles */
@media (width < 1200px) {
    .closeButton {
        top: 10px;
    }

    .contactInformation,
    .contactForm {
        width: 80%;
        height: auto;
    }

    .overlay {
        padding: 2rem;
        overflow: auto;
        margin: 2rem;
        border-radius: 20px;
        box-shadow: 0 0 35.7px 0 #E1DACF;
        height: 100%;
        z-index: 1999;
    }

    .contactUs {
        flex-direction: column !important;
        padding: 1rem !important;
        align-items: center !important;
        height: auto !important;
        margin-top: 40rem;
    }

    .contactInformation,
    .contactForm {
        width: 100%;
        padding: 1rem;
        height: auto;
    }

    .contactInformation {
        height: 50vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .formGroup {
        display: flex;
        flex-direction: column;
        font-size: 1.2rem;
        width: 100%;
    }

    .formgroupTitle {
        margin-top: -1.5rem;
        font-size: 1.2rem;
        color: #fff;
    }

    .formRow {
        flex-direction: column;
    }

    .letterSend {
        display: none;
    }

    .backgrounImg {
        top: 18%;
        left: 45%;
    }

    .socials {
        height: 2.5rem;
        width: 2rem;
    }

    .contactInformation {
        padding: 0.5rem 2rem;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .letterSend {
        display: none;
    }

    .backgrounImg {
        left: 60%;
        top: 30%;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    .letterSend {
        display: none;
    }

    .backgrounImg {
        left: 15%;
        top: 60%;
    }
}

@media (max-width: 480px) {
    .overlay {
        padding: 1rem;
        margin: 1rem;
        z-index: 1999;
    }

    .contactInformation,
    .contactForm {
        padding: 0.5rem;
        height: auto;
    }

    .contactTitles .title {
        font-size: 1.5rem;
    }

    .contactTitles .subtitle {
        font-size: 1rem;
    }

    .contactUs {
        margin-top: 50rem;
    }

    .socials {
        position: static;
        margin-top: 1rem;
        justify-content: center;
        width: 100%;
        gap: 1rem;
    }

    .socials img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
    .textFormGroup {
        transform: translate(0);
    }
    .contactTitles {
        margin-bottom: 2rem;
    }
}

@media (min-width: 768px) {
    .checkbox label {
        flex-basis: calc(33.33% - 0.5rem);
    }
}
@media (max-width: 480px) {
    .checkbox {
        justify-content: flex-start;
    }

    .checkbox label {
        flex-basis: 100%;
        margin-bottom: 0.5rem;
    }
}

@media (max-width: 480px) {
    .sendMessageButtonContainer {
        position: static;
        margin-top: 1rem;
        text-align: center;
    }

    .sendMessageButton {
        width: 100%;
    }

    .textArea {
        /*padding-bottom: 2.5rem;*/
        transform: translate(0);
    }
}

@media (max-width: 1200px) {
    .sendMessageButtonContainer {
        position: static;
        margin-top: 1rem;
        text-align: center;
        width: 100%;
    }

    .sendMessageButton {
        width: 100%;
    }

    .textArea {
        /*padding-bottom: 2.5rem;*/
    }
}


.personalInfoContainer {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.formGroup {
    flex: 1;
    width: 100%;
}

.inputField {
    width: 100%;
    padding: 1.5rem 1rem;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
    .personalInfoContainer {
        flex-direction: column;
    }

    .inputField {
        padding: 1.2rem 1rem;
        font-size: 1.1rem;
    }

    .formGroup {
        margin-bottom: 1rem;
    }
}
