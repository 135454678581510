.header {
    position: relative;
    width: 100%;
    height: 95vh;
    overflow: hidden;
}

.headerVideo,
.headerPhoto {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.headerContent {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    color: white;
    z-index: 1;
    font-family: "Montserrat Alternates", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;

}

.headerContent .content {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 400;
    font-size: 2.5rem;
    text-align: left;
}

.headerContent .headersectionDivider {
    width: 50%;
    height: 4rem;
    margin: 0.3rem;
    border: 0;
    background: #BAA588;
    clip-path: ellipse(49% 2% at 50% 20%);
    position: relative;

}

.headerContent .contactUsBtn {
    position: relative;
    padding: 0.3rem;
    width: 9rem;
    border-radius: 0.4em;
    background: #fff;
    background: transparent;
    text-align: center;
    color: #F0E7E0;
    cursor: pointer;
}


.headerContent .contactUsBtn::before {
    content: '';
    position: absolute;
    top: -9px;
    left: -9px;
    right: -9px;
    bottom: -9px;
    border-radius: 50px;
    background: linear-gradient(90deg, #F0E7E0 0%, rgba(240, 231, 224, 0) 100%);
    z-index: -1;
    padding: 2px;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
}

#portfolio .servicesContent p {
    font-size: 3.3rem;
}

@media (max-width: 1024px) {
    .headerContent {
        top: 80%;
        padding: 0 1.5rem;
    }

    .headerContent .content {
        font-size: 1.5rem;
    }

    .headerContent button {
        padding: 0.8rem 1.5rem;
        font-size: 0.9rem;
        width: 7rem;
    }
}



@media (max-width: 768px) {
    .headerContent {
        top: 55%;
        flex-direction: column;
        align-items: flex-start;
        text-align: center;

        padding: 0 1rem;

    }

    .headerContent .content {
        font-size: 1.2rem;

        width: 100%;

    }

    .headerContent .sectionDivider {
        width: 70%;

    }

    .headerContent button {
        margin-top: 1rem;

        width: 100%;

        padding: 1rem;

        font-size: 1rem;

        width: 7rem;
    }
}

@media (max-width: 480px) {
    .headerContent {
        top: 70%;
        padding: 0 0.5rem;

    }

    .headerContent .content {
        font-size: 1rem;
    }

    .headerContent button {
        padding: 0.8rem;
        font-size: 0.9rem;
    }
}
