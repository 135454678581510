.footer {
    background-color: #f5f5f5;
    padding: 3rem 2rem;
    text-align: center;
    position: relative;
    width: 85%;
    margin: 0 auto;
    margin-top: 5rem;
    border-radius: 40px;
    font-family: "Montserrat Alternates", sans-serif;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.blackBlock {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: #7f1a1a;
    transform: translate(-50%, -50%) rotate(7deg);
    border-radius: 40px;
    z-index: -1000;
    opacity: 0.5;

}

.footerContainer {
    width: 85%;
    position: relative;
}

.footer {
    background-color: #f5f5f5;
    padding: 3rem 2rem;
    text-align: center;
    position: relative;
    width: 85%;
    margin: 0 auto;
    margin-top: 5rem;
    border-radius: 40px;
    font-family: "Montserrat Alternates", sans-serif;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    /* Ensures the footer is above the black block */
}

.backBlock {
    position: absolute;
    top: 55%;
    left: 50%;
    width: 85%;
    height: 70%;
    background-color: rgba(255, 224, 181, 0.33);
    transform: translate(-50%, -50%) rotate(7deg);
    border-radius: 40px;
    z-index: 0;
    opacity: 0.5;
}

.footerContent {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footerContent h2 {
    font-size: 2.5rem;
    color: #5F5F5F;
    margin-bottom: 1rem;
}

.footerContent p {
    font-size: 0.8rem;
    margin-bottom: 2rem;
    color: #5F5F5F;
    width: 60%;
    line-height: 1.3rem;
}

.contactButton {
    background-color: #5F5F5F;
    color: #fff;
    border: none;
    padding: 1rem;
    border-radius: 25px;
    cursor: pointer;
    font-size: 12px;
    font-family: "Montserrat Alternates", sans-serif;
    width: 9rem;
}

.footerBottom {
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.footerBottom p {
    margin-top: 3rem;
    font-size: 0.7rem;
    color: #0A142F;
}

.footerNav {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
}

.footerNav a {
    margin: 0 10px;
    text-decoration: none;
    color: #000000;
}

.footerSocial {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.footerSocial a {
    color: #000000;
    font-size: 20px;
}

.footerSocial a:hover {
    color: #555;
}
.footerSocial img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.footerContainer .logo {
    width: 13rem;
}

.footerContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    gap: 2rem;
}

.line {
    margin-top: 5rem;
    width: 78%;
    height: 1px;
    background-color: #0A142F;
}
.link {
    color: #000;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
}

.link:hover {
    color: #007bff;
    transform: scale(1.05);
    text-decoration: underline;
}

.link:active {
    color: #0056b3;
    transform: scale(1);
}

.link:focus {
    outline: none;
    box-shadow: 0 0 0 2px #0056b3;
}

/* Media Queries */
@media (max-width: 1200px) {

    .footerContent {
        width: 80%;
        padding: 1rem;
    }

    .footerContent h2 {
        font-size: 2rem;
    }

    .footerContent p {
        font-size: 0.9rem;
        width: 80%;
    }

    .contactButton {
        width: 8rem;
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .backBlock {
        display: none;
    }

    .footer {
        padding: 2rem 1rem;
        width: 95%;
        margin-top: 3rem;
    }

    .footerContent {
        width: 90%;
    }

    .footerContent h2 {
        font-size: 1.8rem;
    }

    .footerContent p {
        font-size: 0.8rem;
        width: 90%;
    }

    .contactButton {
        width: 7rem;
        font-size: 12px;
    }

    .footerNav a {
        margin: 0 5px;
    }

    .footerSocial a {
        font-size: 18px;
    }

    .footerContainer {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 1rem;
        width: 100%;
        max-width: 90%;
    }

    .footerContent {
        width: 100%;
    }

    .footerContent h2 {
        font-size: 1.5rem;
    }

    .footerContent p {
        font-size: 0.7rem;
        width: 100%;
    }

    .contactButton {
        width: 6rem;
        font-size: 10px;
    }

    .footerNav {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .footerNav a {
        margin: 0 3px;
    }

    .footerSocial a {
        font-size: 16px;
    }

    .line {
        width: 100%;
    }

    .footerContainer {
        display: flex;
        flex-direction: column-reverse;
    }
}
