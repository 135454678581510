.service {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
    width: 85%;
    margin: 0 auto;
    margin-top: 3rem;
    position: relative;
    font-family: "Montserrat Alternates", sans-serif;
    gap: 20px;
}

.reversed {
    flex-direction: row-reverse;
    gap: 20px;
}

.image-container {
    width: 50%;
    position: relative;
    border-radius: 40px;
    z-index: 2;
    overflow: hidden;
}

.image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 40px;
    cursor: pointer;
}

.image-container::before,
.image-container::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0;
    z-index: 3;
    pointer-events: none;
}

.image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(184, 184, 187, 0.7), rgba(224, 222, 213, 0));
    z-index: 1;
}

.image-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(184, 184, 187, 0.7), rgba(224, 222, 213, 0));
    z-index: 1;
}

.title-overlay {
    position: absolute;
    bottom: 20px;
    color: #565656;
    text-align: left;
    font-size: 2rem;
    font-weight: 500;
    z-index: 4;
    padding: 0 20px;
    width: calc(100% - 40px);
    box-sizing: border-box;
}

.left  {
    left: 0;
}

.right  {
    left: 0;
    text-align: right;
}

.serviceSectionDivider {
    width: 5rem;
    height: 3px;
    background: #565656;
    clip-path: ellipse(50% 50% at 50% 50%);
    border: none;
    margin-top: 5px;
}

.left .serviceSectionDivider {
    margin-left: 0;
    margin-right: auto;
}

.right .serviceSectionDivider {
    margin-left: auto;
    margin-right: 0;
}

.text-container {
    width: 50%;
    display: flex;
    position: relative;
    z-index: 1;
    margin-left: -10px;
    justify-content: center;
    align-items: center;
}

.reversed .text-container {
    margin-left: 0;
    margin-right: -10px;
    justify-content: flex-end;
}

.text {
    height: 20rem;
    padding: 20px;
    background: linear-gradient(270deg, rgba(227, 220, 204, 0.33) -13.52%, #E3DCCC 106.54%);
    border-radius: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 26px;
}

.reversed .text {
    align-items: flex-end;
    text-align: right;
}

.text h2 {
    margin: 0 0 10px;
    font-size: 24px;
}

.text p {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #565656;
}

.container {
    padding: 20px;
    transform: translateY(-130px);
}

@media (max-width: 768px) {
    .text {
        text-align: center;
        align-items: center;
    }

    .reversed .text {
        text-align: center;
        align-items: center;
    }

    .service {
        flex-direction: column;
        margin-top: 1rem;
        gap: 10px;
    }

    .reversed {
        flex-direction: column;
    }

    .image-container,
    .text-container {
        width: 100%;
    }

    .text-container {
        margin-top: -10px;
    }

    .text {
        padding: 2rem 2rem;
        border-radius: 8px 8px 15px 15px;
    }

    .image {
        border-radius: 20px 20px 0 0;
    }

    .title-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-size: 1.8rem;
        bottom: 10px;
    }

    .serviceSectionDivider {
        width: 4rem;
        margin-top: 10px;
    }
}

@media (max-width: 530px) {
    .text-container {
        margin: 0;
    }

    .text {
        padding: 1rem;
        border-radius: 8px;
    }

    .title-overlay {
        font-size: 1.6rem;
        bottom: 20px;
    }
}

@media (max-width: 480px) {
    .text-container {
        margin: -5px;
    }

    .text {
        height: auto;
        padding: 1rem;
    }

    .title-overlay {
        font-size: 1.4rem;
        bottom: 5px;
    }

    .serviceSectionDivider {
      display: none;
    }
    .image img{
        background-position: 10px;
    }
}

@media (max-width: 360px) {
    .text-container {
        margin-top: -50px;
        height: auto;
        padding: 2rem;
    }

    .title-overlay {
        font-size: 1.2rem;
        top: auto;
        bottom: -20px;
    }
}
