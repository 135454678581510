.navList li,
.mobileMenu ul li {
    transition: color 0.3s ease, transform 0.3s ease, border-bottom 0.3s ease;
    cursor: pointer;
}

.navList li:hover {
    color: #BAA588;
    transform: translateY(-2px);
}

.navList li:hover::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #BAA588;
    bottom: -5px;
    left: 0;
    border-radius: 50%;
    clip-path: ellipse(50% 50% at 50% 50%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 1;
    transform: scaleX(0);
}
.mobileMenu ul li:hover {
    color: #BAA588;
}

.mobileMenu ul li:hover::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #BAA588;
    bottom: -5px;
    left: 0;
    border-radius: 50%;
    clip-path: ellipse(50% 50% at 50% 50%);

    transition: all 0.3s ease;
    opacity: 1;
}


.navbar {
    background-color: #fff;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 0 0 60% 60% / 0 0 30% 30%;
    width: 100%;
    height: 4rem;
    position: fixed;
    top: 0;
    z-index: 2000;
    transition: all 0.3s ease;
    box-shadow: 0 20px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.navContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    width: 90%;
}

.navList {
    display: flex;
    gap: 3.5rem;
    text-decoration: none;
    list-style: none;
    position: relative;
}

.navList li {
    font-family: "Montserrat Alternates", sans-serif;
    letter-spacing: 1px;
    font-size: 1.25rem;
    position: relative;
}

.navbarCurve {
    position: absolute;
    top: -5%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-color: #fff;
    width: 22rem;
    height: 6rem;
    border-radius: 0 0 100% 100% / 0 0 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.navContainer {
    width: 100%;
    height: 4.5rem;
    border-radius: 0 0 50% 90% / 0 0 30% 30%;
    position: fixed;
    top: 0;
    transition: box-shadow 0.3s ease;
    backdrop-filter: blur(90px);
    -webkit-backdrop-filter: blur(20px);
    z-index: 2000;
}

.logo {
    height: 3.5rem;
}

.burgerMenu {
    height: 1rem;
    cursor: pointer;
    display: none;
    z-index: 999;
}

.mobileMenu {
    display: none;
}

nav a {
    color: #000;
}

.navList li.active {
    position: relative;
    color: #000;
}

.navList li.active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #BAA588;
    bottom: -5px;
    left: 0;
    border-radius: 50%;
    clip-path: ellipse(50% 50% at 50% 50%);
}

.sectionDivider {
    width: 5rem;
    height: 2px;
    margin: 0 auto;
    background: #BAA588;
    clip-path: ellipse(49% 2% at 50% 7%);
    margin-top: 1rem;
}

.mobileMenu {
    display: none;
    transform: translateY(-100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

@media (width < 1200px) {
    .burgerMenu {
        height: 1rem;
        width: 1.5rem;
    }

    .navContainer {
        z-index: 3000;
    }

    .navList {
        display: none;
    }

    .burgerMenu {
        display: block;
        position: absolute;
        top: 28%;
        left: 85%;
        height: 2rem;
        z-index: 2001;
    }

    .navbarCurve {
        width: 15rem;
        height: 6rem;
        z-index: 1999;
    }

    .navbar {
        z-index: 2000;
    }

    .logo {
        height: 2.5rem;
    }

    .mobileMenu {
        display: block;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(80px);
        background: linear-gradient(to right,
        rgba(14, 11, 11, 0.798) 0%,
        rgba(4, 4, 3, 0.675) 100%);
        transition: box-shadow 0.3s ease;
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        padding: 1rem;
        opacity: 1;
        transform: translateY(0);
    }

    .mobileMenu ul {
        list-style: none;
        padding: 0;
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
    }

    .mobileMenu ul li {
        padding: 0.5rem 0;
        text-align: center;
        font-size: 2rem;
        color: #FFFFFF;
        font-family: "Montserrat Alternates", sans-serif;
        font-weight: 400;
        position: relative;
    }

    .mobileMenu ul li:hover::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #BAA588;
        bottom: -5px;
        left: 0;
        border-radius: 50%;
        clip-path: ellipse(50% 50% at 50% 50%);
        transition: opacity 0.3s ease;
        opacity: 1;
    }

    .mobileMenu ul li a {
        color: #FFFFFF;
        text-decoration: none;
        font-weight: 200;
    }
}
