.parentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: auto;
}

.sliderContainer {
    max-width: 1400px;
    width: 100%;
    min-width: 0;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    /*margin-top: 2rem;*/
}

.slider {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 2em 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.slide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 300ms, transform 300ms;
    height: 30rem;
    margin-bottom: 3rem;
    overflow: hidden;
    z-index: 0;
    opacity: 0.8;
    /*border-radius: 100px !important;*/

}

.swiper-slide-active {
    transform: scale(1.2) translateY(20px);
    z-index: 1;
    opacity: 1;
}

.slideText {
    position: absolute;
    z-index: 99;
    bottom: -10px;
    top: 95%;
    font-size: 1.3rem;
    color: #FFF;
    text-align: center;
    width: 100%;
    font-family: "Montserrat Alternates", sans-serif;
}

.slideBackground {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    border-radius: 50px;
    padding: 10px;
    width: 26rem;
    height: 30rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
}

.slideImage {
    max-width: 100%;
    max-height: 100%;
    transition: transform 400ms;
    position: relative;
    z-index: 1;
}

.swiper-slide-active .slideBackground {
    height: 32rem;
    width: 28rem;
    border-radius: 10%;
}

.controllers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1em;
}

.sliderBtn {
    border: 1px solid #574B44;
    padding: 1rem 2rem;
    cursor: pointer;
    outline: none;
    border-radius: 50px;
    transition: background-color 300ms;
    background-color: #E1DACF;
    text-align: center;
}

.viewMoreContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0;
}

.viewMoreBtn {
    position: relative;
    border-radius: 0.4em;
    background: transparent;
    padding: 18px 39px;
    width: 210px;
    height: 65px;
    color: #F0E7E0;
    border: 2px solid transparent;
    background-clip: padding-box;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #777168;
    font-size: 1.1rem;
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 500;
}

.viewMoreBtn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 64px;
    padding: 2px;
    background: linear-gradient(97.37deg, #777168 2.35%, rgba(119, 113, 104, 0) 85.79%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    z-index: -1;
}

@media (max-width: 768px) {
    .swiper-wrapper {
        grid-template-columns: 1fr;
    }

    .slide {
        opacity: 1;
    }

    .swiper-slide-active {
        transform: scale(1) translateY(0);
    }

    .swiper-slide-active .slideBackground {
        width: 22rem;
        height: 28rem;
    }
}

@media (max-width: 480px) {
    .swiper-wrapper {
        grid-template-columns: 1fr;
    }

    .slide {
        margin-top: -2rem;
        opacity: 1;
    }

    .viewMoreBtn {
        font-size: 1rem;
        width: 150px;
        padding: 1.5rem;
    }

    .swiper-slide-active {
        transform: scale(1) translateY(0);
    }

    .swiper-slide-active .slideBackground {
        width: 22rem;
        height: 28rem;
    }

    .controllers button {
        width: 30px;
    }

    .smaller {
        width: 100%;
        max-width: 100%;
    }

    .slideImage.smaller {
        width: 100%;
        height: auto;
    }
}


@media (max-width: 1200px) {
    .swiper-wrapper {
        grid-template-columns: 1fr;
    }

    .slide {
        opacity: 1;
    }

    .swiper-slide-active {
        transform: scale(1) translateY(0);
    }

    .swiper-slide-active .slideBackground {
        width: 22rem;
        height: 28rem;
    }
}

.onTop {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.slideImage.onTop {
    max-width: 100%;
    height: auto;
    transform: translateY(-40px);
    position: absolute;
    left: 50%;
    transform: translate(-40%, -40px);
}

.smaller {
    max-width: 80%;
    height: auto;
}

.slideImage.smaller {
    width: 80%;
    height: auto;
}
@media (max-width: 768px) {
    .sliderContainer {
        width: 100%;
        padding: 0 15px;
    }

    .slideImage {
        width: 100%;
        height: auto;
    }

    .slideText {
        font-size: 1.2rem;
    }
    .smaller {
        max-width: 100%;
        height: auto;
    }
    .onTop {
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: visible;
    }

    .slideImage.onTop {
        max-width: 130%;
        width: 150%;
        height: auto;
        position: absolute;
        left: 50%;
        transform: translate(-40%, -66px);
    }
    .slideImage img{
       width: 100px;
        height: 100px;
    }
    .controllers {
        width: 100%;
        margin-top: 1em;
        justify-content: space-between;
        align-items: center;
    }

    .controllers button {
        width: 60px;
        height: 40px;
        padding: 6px;
    }
    .viewMoreBtn {
        font-size: 0.8rem;
        width: 120px;
        height: 40px;
        padding: 8px 20px;
    }
}

