/* General styling for .about section */
.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat Alternates", sans-serif;
    padding: 0 1rem;
    transform: translateY(-100px);
}

/* Loading container styling */
.loadingContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Services, team, and mission titles */
.servicesContent,
.ourTeam .title,
.whatWeDo .title,
.ourMission .ourMissionTitle,
.ourValues .ourValuesTitle {
    text-align: center;
    margin-bottom: 1rem;
}

/* Text styling */
.servicesContent p,
.ourTeam .title p,
.whatWeDo .title p,
.ourMissionTitle p,
.ourValuesTitle p {
    font-size: 2rem;
    color: #565656;
}

/* Services content adjustment */
.servicesContent p {
    font-size: 2.5rem;
}

/* About section divider styling */
.aboutSectionDivider {
    width: 8%;
    margin: 0.3rem auto;
    border: 0;
    height: 4rem;
    background: #BAA588;
    clip-path: ellipse(49% 2% at 50% 7%);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.ourValuesTitle,
.ourMissionTitle {
    position: relative;
}

/* Mission section divider */
.ourMissionAboutSectionDivider {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 5rem;
    height: 3px;
    background: #555555;
    clip-path: ellipse(50% 50% at 50% 50%);
    border: none;
}

/* Subtitle styling */
.aboutSubtitle .firstSubtitle {
    margin: 1rem 0;
}

/* Title adjustments */
.title {
    position: relative;
    padding-bottom: 1rem;
}

.title p {
    margin: 0;
    padding-bottom: 1rem;
}

/* Subtitle adjustments */
.aboutSubtitle {
    margin: 1rem auto;
    max-width: 70%;
    text-align: center;
}

.aboutSubtitle .firstSubtitle {
    font-weight: 500;
    color: #565656;
    font-size: 1.6rem;
}

/* Content container for about us */
.aboutusContent {
    margin-top: 2rem;
    max-width: 85%;
    border: 2px solid #C6BFB4;
    padding: 2rem 4rem;
    text-align: center;
    border-radius: 20px;
    background: #E3DCCC6B;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.aboutusContent p {
    width: 94%;
    color: #565656;
    font-size: 1.5rem;
    margin: 1rem 0;
    line-height: 2rem;
    font-weight: 400;
}

/* Team section styling */
.ourTeam {
    margin-top: 3rem;
    width: 100%;
    position: relative;
}

.ourTeamSlider {
    width: 100%;
    margin: 2rem auto;
}

/* Slider content */
.slideContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 300px;
    height: 450px;
}

.slideContent img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 20px;
    object-fit: cover;
}

/* Text overlay for sliders */
.textOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    backdrop-filter: blur(5px);
}

/* Hover effects */
.slideContent:hover .textOverlay {
    opacity: 1;
}

.slideContent:hover .memberInfo {
    opacity: 0;
}

/* Member information styling */
.memberInfo {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 2px solid #FFE0B5;
    transition: all ease-in 0.2s;
    padding-left: 10px;
}

/* Member name styling */
.name {
    color: #fff;
    font-size: 1.1rem;
    font-weight: bold;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

/* Member role styling */
.role {
    color: #fff;
    font-size: 0.9rem;
    margin-top: 0.3rem;
    text-align: left;
}

/* Controller buttons */
.controllers {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    margin-top: 1rem;
}

.sliderBtn {
    border: 1px solid #574B44;
    padding: 1rem;
    cursor: pointer;
    outline: none;
    border-radius: 50px;
    transition: background-color 300ms;
    width: 6rem;
    background-color: #E1DACF;
    text-align: center;
}

.sliderBtn:hover {
    background-color: #d8c8b3;
}

/* What we do section */
.whatWeDo {
    margin-top: 3rem;
    width: 100%;
}

.whatWeDoContent {
    width: 85%;
    margin: 0 auto;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.whatWeDo .title p {
    width: 30%;
    margin: 0 auto;
}

/* Our mission and values */
.ourMission,
.ourValues {
    flex: 1 1 calc(50% - 2rem);
    border: 1px solid #C6BFB4;
    padding: 2rem 3rem;
    border-radius: 40px;
    text-align: center;
    height: 20rem;
    min-width: 300px;
    background-color: #EEE8DF;
}

.ourMissionContent,
.ourValuesContent {
    margin-top: 2rem;
    color: #555555;
    font-size: 1rem;
}

.ourMissionTitle p,
.ourValuesTitle p {
    font-size: 1.8rem;
}

/* Footer slider container */
.footerSliderContainer {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
    position: relative;
}

.footerSlider {
    width: 100%;
    margin: 0 auto;
}

/* Footer slider content */
.footerSlideContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 650px;
    background-color: #E3DCCC6B;
    border-radius: 20px;
    overflow: hidden;
}

.footerSlideImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

/* Custom pagination */
.custom-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.custom-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #BAA588;
    opacity: 1;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
}

.custom-pagination .swiper-pagination-bullet-active {
    background-color: #BAA588;
}

/* Footer controls */
.footerControllers {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
}

.footerSliderBtn {
    border: 1px solid #574B44;
    padding: 1rem 2rem;
    cursor: pointer;
    outline: none;
    border-radius: 50px;
    transition: background-color 300ms;
    background-color: #E1DACF;
    text-align: center;
}

.footerSliderBtn:hover {
    background-color: #d8c8b3;
}

/* Media queries for screens between 776px and 1388px */
@media (min-width: 776px) and (max-width: 1388px) {
    .aboutusContent {
        max-width: 90%;
        padding: 2rem;
    }

    .slideContent {
        width: calc(100% - 20px);
        max-width: 300px;
        height: auto;
    }

    .whatWeDo .title p,
    .servicesContent p,
    .ourTeam .title p,
    .whatWeDo .title p,
    .ourMissionTitle p,
    .ourValuesTitle p {
        font-size: 1.6rem;
    }

    .servicesContent p {
        font-size: 2rem;
    }

    .ourMission,
    .ourValues {
        flex: 1 1 100%;
        max-width: 100%;
        margin-bottom: 2rem;
    }
}

/* Adjustments for medium screens */
@media (max-width: 768px) {
    .aboutSectionDivider {
        width: 6rem;
    }

    .whatWeDo .title p {
        width: 80%;
    }

    .aboutusContent {
        max-width: 90%;
        padding: 1rem;
    }

    .whatWeDoContent {
        flex-direction: column;
        width: 100%;
        padding: 1rem;
    }

    .ourMission,
    .ourValues {
        width: 100%;
        padding: 1rem;
        min-width: unset;
    }

    .controllers {
        justify-content: center;
    }

    .slideContent {
        width: 250px;
        height: 400px;
    }

    .slideContent img {
        height: 100%;
        width: 100%;
    }

    .textOverlay p {
        font-size: 0.8rem;
        line-height: 1.2rem;
    }

    .memberInfo {
        font-size: 0.8rem;
    }

    .name {
        font-size: 1rem;
    }

    .role {
        font-size: 0.8rem;
    }

    .servicesContent p,
    .ourTeam .title p,
    .whatWeDo .title p,
    .ourMissionTitle p,
    .ourValuesTitle p {
        font-size: 1.8rem;
    }

    .servicesContent p {
        font-size: 2rem;
    }

    .aboutSubtitle .firstSubtitle {
        font-size: 1.4rem;
    }

    .aboutusContent p {
        font-size: 1.2rem;
    }
}

/* Adjustments for small screens */
@media (max-width: 480px) {
    .aboutSubtitle .firstSubtitle {
        font-size: 1.4rem;
    }

    .aboutusContent {
        max-width: 95%;
        padding: 1rem;
    }

    .aboutusContent p {
        font-size: 1rem;
    }

    .whatWeDoContent {
        padding: 0.5rem;
    }

    .ourMission,
    .ourValues {
        padding: 0.5rem;
    }

    .controllers {
        justify-content: center;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .slideContent {
        width: 230px;
        height: 400px;
        margin: auto;
    }

    .textOverlay p {
        font-size: 0.7rem;
        line-height: 1rem;
    }

    .memberInfo {
        font-size: 0.7rem;
    }

    .name {
        font-size: 0.9rem;
    }

    .role {
        font-size: 0.7rem;
    }

    .servicesContent p,
    .ourTeam .title p,
    .whatWeDo .title p,
    .ourMissionTitle p,
    .ourValuesTitle p {
        font-size: 1.6rem;
    }

    .servicesContent p {
        font-size: 1.8rem;
    }

    .aboutSubtitle .firstSubtitle {
        font-size: 1.2rem;
    }

    .aboutusContent p {
        font-size: 0.9rem;
    }
}
